import "@userbrain/website-ui/global.css";
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onInitialClientRender = () => {
  // Redirect from the root to the English version only in development
  if (
    process.env.NODE_ENV === 'development' &&
    typeof window !== "undefined" && 
    window.location.pathname === "/"
  ) {
    window.location.replace("/en/");
  }
};
